var render = function render(){
  var _vm$formData$customer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "title": "编辑订单",
      "width": "710px",
      "top": "10px",
      "close-on-click-modal": false
    },
    on: {
      "open": _vm.open,
      "close": _vm.cancel
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "86px",
      "size": "medium"
    }
  }, [_c('div', {
    staticClass: "form-module"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("基本信息")]), _c('div', {
    staticClass: "item-box"
  }, [_c('el-form-item', {
    attrs: {
      "label": "成交项目:",
      "prop": "estateId",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-select', {
    staticClass: "f-remote-select",
    attrs: {
      "clearable": "",
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "placeholder": "请选择",
      "remote-method": _vm.remoteEstate,
      "loading": _vm.estateLoading
    },
    on: {
      "change": _vm.changeEstate
    },
    model: {
      value: _vm.formData.estateId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "estateId", $$v);
      },
      expression: "formData.estateId"
    }
  }, _vm._l(_vm.estateList, function (item) {
    return _c('el-option', {
      key: item.estateId,
      attrs: {
        "value": item.estateId,
        "label": item.name
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "成交金额:",
      "prop": "amount",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "amount", $$v);
      },
      expression: "formData.amount"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "成交日期:",
      "prop": "dealDate",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "placeholder": "选择日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd HH:mm:ss"
    },
    model: {
      value: _vm.formData.dealDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "dealDate", $$v);
      },
      expression: "formData.dealDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "成交房源:",
      "prop": "houseInfo"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.houseInfo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "houseInfo", $$v);
      },
      expression: "formData.houseInfo"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "报备带看:",
      "prop": "reportId"
    }
  }, [_c('el-select', {
    staticClass: "f-remote-select",
    attrs: {
      "clearable": "",
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "placeholder": "请选择",
      "remote-method": _vm.remoteReport,
      "loading": _vm.reportLoading
    },
    on: {
      "change": _vm.changeReport
    },
    model: {
      value: _vm.formData.reportId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportId", $$v);
      },
      expression: "formData.reportId"
    }
  }, _vm._l(_vm.reportArr, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "建筑面积:",
      "prop": "floorSpace",
      "rules": [_vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.floorSpace,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "floorSpace", $$v);
      },
      expression: "formData.floorSpace"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("㎡")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "合作渠道:",
      "prop": "mechanismId",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-select', {
    staticClass: "f-remote-select",
    attrs: {
      "clearable": "",
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "placeholder": "请选择",
      "remote-method": _vm.remoteMechanism,
      "loading": _vm.companyLoading,
      "disabled": !!_vm.formData.reportId
    },
    on: {
      "change": _vm.changeCompany
    },
    model: {
      value: _vm.formData.mechanismId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "mechanismId", $$v);
      },
      expression: "formData.mechanismId"
    }
  }, _vm._l(_vm.companyArr, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    });
  }), 1)], 1), _c('el-form-item', {
    staticClass: "broker-item",
    attrs: {
      "label": "经纪人:",
      "prop": "agentPhone",
      "rules": [_vm.$formRules.tel]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.agentName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "agentName", $$v);
      },
      expression: "formData.agentName"
    }
  }), _c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.agentPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "agentPhone", $$v);
      },
      expression: "formData.agentPhone"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "form-module"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("客户信息")]), (_vm$formData$customer = _vm.formData.customerList) !== null && _vm$formData$customer !== void 0 && _vm$formData$customer.length ? _c('div', {
    staticClass: "user-list"
  }, [_c('div', [_c('div', [_vm._v("客户姓名")]), _c('div', [_vm._v("联系电话")]), _c('div', [_vm._v("备注说明")])]), _vm._l(_vm.formData.customerList, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('el-input', {
      attrs: {
        "placeholder": "请输入",
        "size": "medium"
      },
      model: {
        value: item.customerName,
        callback: function callback($$v) {
          _vm.$set(item, "customerName", $$v);
        },
        expression: "item.customerName"
      }
    }), _c('el-input', {
      class: {
        'is-error': item.verify
      },
      attrs: {
        "placeholder": "请输入",
        "size": "medium",
        "maxlength": "11"
      },
      on: {
        "change": function change($event) {
          return _vm.validatePhone(item, item.customerPhone);
        }
      },
      model: {
        value: item.customerPhone,
        callback: function callback($$v) {
          _vm.$set(item, "customerPhone", $$v);
        },
        expression: "item.customerPhone"
      }
    }), _c('el-input', {
      attrs: {
        "placeholder": "请输入",
        "size": "medium"
      },
      model: {
        value: item.remark,
        callback: function callback($$v) {
          _vm.$set(item, "remark", $$v);
        },
        expression: "item.remark"
      }
    }), _vm.formData.customerList.length > 1 ? _c('i', {
      staticClass: "el-icon-remove-outline",
      on: {
        "click": function click($event) {
          return _vm.deleteUser(index);
        }
      }
    }) : _vm._e()], 1);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "add-user",
    on: {
      "click": _vm.addUser
    }
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _vm._v("添加客户 ")])]), _c('div', {
    staticClass: "form-module"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("佣金结算")]), _c('div', {
    staticClass: "item-box"
  }, [_c('el-form-item', {
    attrs: {
      "label": "佣金收入:",
      "prop": "settlementCommission",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.settlementCommission,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "settlementCommission", $$v);
      },
      expression: "formData.settlementCommission"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "现金奖励:",
      "prop": "settlementCashPrize",
      "rules": [_vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.settlementCashPrize,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "settlementCashPrize", $$v);
      },
      expression: "formData.settlementCashPrize"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "其他收入:",
      "prop": "settlementOtherIncome",
      "rules": [_vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.settlementOtherIncome,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "settlementOtherIncome", $$v);
      },
      expression: "formData.settlementOtherIncome"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "合计应结:",
      "prop": "amountConclusion"
    }
  }, [_c('span', {
    staticClass: "red bold fs15"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.calcAmount)))])])], 1)]), _c('div', {
    staticClass: "form-module"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("更多信息")]), _c('div', {
    staticClass: "more-info"
  }, [_c('div', {
    staticClass: "mb20"
  }, [_c('div', [_vm._v("图片附件")]), _c('div', {
    staticClass: "file-list-box"
  }, [_c('el-upload', {
    staticClass: "upload-images mr10",
    attrs: {
      "action": "",
      "accept": _vm.accept,
      "list-type": "picture-card",
      "multiple": "",
      "auto-upload": false,
      "show-file-list": false,
      "file-list": _vm.fileList,
      "on-change": _vm.imgChangeNUpload
    }
  }, [_c('div', {
    staticClass: "default-upload-btn",
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('i', {
    staticClass: "el-icon-plus"
  })])]), _vm._l(_vm.fileList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "file-list"
    }, [_c('el-image', {
      attrs: {
        "fit": "cover",
        "src": item.url
      }
    }, [_c('i', {
      staticClass: "el-icon-picture",
      attrs: {
        "slot": "error"
      },
      slot: "error"
    })]), _c('span', {
      staticClass: "upload-actions"
    }, [_c('span', {
      on: {
        "click": function click($event) {
          return _vm.handlePreview(item);
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-zoom-in"
    })]), _c('span', {
      on: {
        "click": function click($event) {
          return _vm.handleRemove(item);
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-delete"
    })])])], 1);
  })], 2)]), _c('div', [_c('div', [_vm._v("备注说明")]), _c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": "3",
      "resize": "none",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  })], 1)])])]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.btn_loading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")])], 1), _c('el-dialog', {
    attrs: {
      "title": "大图预览",
      "visible": _vm.previewImageVisible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.previewImageVisible = $event;
      }
    }
  }, [_c('img', {
    attrs: {
      "width": "100%",
      "src": _vm.previewImageUrl,
      "alt": ""
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }