<template>
  <div>
    <el-drawer
      v-bind="$attrs"
      v-on="$listeners"
      :with-header="false"
      size="427"
      @open="handleOpen"
      @close="closeDrawer"
    >
      <template v-if="detailForm.id">
        <div
          class="make-order-detail"
          :class="{ 's-end': detailForm.status == 4 }"
        >
          <div class="line" :class="'line-' + detailForm.status"></div>
          <div class="base-info">
            <div class="head">
              <div>
                <div class="fs16 mb3">NO.{{ detailForm.dealNumber }}</div>
                <div class="fs13 color9">
                  报单 {{ detailForm.inUserName }}
                  {{ detailForm.inDate | formatDate(12) }}
                </div>
              </div>
              <i class="el-icon-close" @click="closeDrawer"></i>
            </div>
            <div class="status" :class="'status-' + detailForm.status">
              <div
                class="status-icon"
                :style="'background:' + iconMap[detailForm.status].bg"
              >
                <i
                  class="iconfont"
                  :class="iconMap[detailForm.status].color"
                ></i>
              </div>
              <div>{{ statusMap[detailForm.status] }}</div>
              <div class="fs14 color9" v-if="detailForm.status == 2">
                已结金额 ¥{{ detailForm.amountEnd | toThousands }}，未结金额
                ¥<template
                  v-if="detailForm.amountWait && detailForm.amountWait >= 0"
                  >{{ detailForm.amountWait | toThousands }}</template
                ><template v-else>0</template>
              </div>
            </div>
            <div class="project">
              <div class="p-name">
                {{ detailForm.estateName }} {{ detailForm.houseInfo }}
                {{ detailForm.floorSpace ? detailForm.floorSpace + "㎡" : "" }}
              </div>
              <div class="color9">成交项目</div>
            </div>
            <div class="label-box">
              <div>
                <div>成交金额</div>
                <div>{{ (detailForm.amount || 0) | toThousands }}元</div>
              </div>
              <div>
                <div>成交日期</div>
                <div>{{ detailForm.dealDate | formatDate(5) }}</div>
              </div>
              <div>
                <div>建筑面积</div>
                <div>
                  {{
                    detailForm.floorSpace ? detailForm.floorSpace + "㎡" : "-"
                  }}
                </div>
              </div>
              <div>
                <div>报备带看</div>
                <div
                  class="color566 pointer"
                  v-if="detailForm.reportInfo"
                  @click="reportDetailVisible = true"
                >
                  {{ detailForm.reportInfo.customerName }}
                  {{
                    detailForm.reportInfo.reportTelephoneReqVos[0]?.telephone
                  }}
                </div>
                <div v-else>-</div>
              </div>
              <div>
                <div>合作渠道</div>
                <div v-if="detailForm.mechanismId == -1">自然到访</div>
                <div v-else-if="detailForm.mechanismName">
                  {{
                    [detailForm.mechanismName, detailForm.mechanismStoreName]
                      | link("-")
                  }}
                </div>
                <div v-else>-</div>
              </div>
              <div>
                <div>经纪人姓名</div>
                <div>{{ detailForm.agentName || "-" }}</div>
              </div>
              <div>
                <div>经纪人电话</div>
                <div v-if="detailForm.agentPhone">
                  {{ detailForm.agentPhone | formatPhone }}
                </div>
                <div v-else>-</div>
              </div>
              <div class="border"></div>
              <template v-if="detailForm.customerList?.length">
                <dl
                  class="label-box"
                  v-for="(item, index) in detailForm.customerList"
                  :key="index"
                >
                  <div>
                    <div>客户姓名</div>
                    <div>{{ item.customerName || "-" }}</div>
                  </div>
                  <div>
                    <div>联系电话</div>
                    <div v-if="item.customerPhone">
                      {{ item.customerPhone | formatPhone }}
                    </div>
                    <div v-else>-</div>
                  </div>
                </dl>
                <div class="border"></div>
              </template>
              <div>
                <div>佣金</div>
                <div>
                  ¥{{ (detailForm.settlementCommission || 0) | toThousands }}
                </div>
              </div>
              <div>
                <div>现金奖</div>
                <div>
                  ¥{{ (detailForm.settlementCashPrize || 0) | toThousands }}
                </div>
              </div>
              <div>
                <div>其他收入</div>
                <div>
                  ¥{{ (detailForm.settlementOtherIncome || 0) | toThousands }}
                </div>
              </div>
            </div>
            <div class="price-total">
              <span class="color9">合计应结</span>
              <span class="price"
                >¥<span>{{
                  (detailForm.amountConclusion || 0) | toThousands
                }}</span></span
              >
            </div>
          </div>
          <div
            class="collect-record"
            v-if="
              (detailForm.status == 2 || detailForm.status == 3) &&
                detailForm.paymentRecords?.length
            "
          >
            <div class="color9 mb20">收款记录</div>
            <div
              v-for="(item, index) in detailForm.paymentRecords"
              :key="index"
              class="record-list"
            >
              <div class="fs16 bold mb12">¥{{ item.amount | toThousands }}</div>
              <div class="r-list-info">
                <div class="color9">
                  <div>
                    <span>收款日期：</span
                    >{{ item.paymentDate | formatDate(2) }}
                  </div>
                  <div v-if="item.remark" class="flex">
                    <span>备注说明：</span
                    ><span class="r-remark">{{ item.remark }}</span>
                  </div>
                  <div><span>录入人：</span>{{ item.inUserName }}</div>
                  <div>
                    <span>录入时间：</span>{{ item.inDate | formatDate(9) }}
                  </div>
                </div>
                <i class="el-icon-edit-outline" @click="editReceipt(item)"></i>
              </div>
            </div>
            <div class="price-total">
              <template v-if="detailForm.status == 2">
                <span class="color9">未结金额</span>
                <span class="price mr10"
                  >¥<span
                    v-if="detailForm.amountWait && detailForm.amountWait >= 0"
                    >{{ detailForm.amountWait | toThousands }}</span
                  ><span v-else>0</span></span
                >
              </template>
              <span class="color9">{{
                detailForm.status == 2 ? "已结金额" : "实结金额"
              }}</span>
              <span class="price red"
                >¥<span>{{ detailForm.amountEnd | toThousands }}</span></span
              >
            </div>
          </div>
          <div
            class="make-remark"
            v-if="detailForm.remark || detailForm.annexList?.length"
          >
            <tmeplate v-if="detailForm.remark">
              <div class="color9 mb8">备注说明</div>
              <div class="fs15 mb16 remark-text">{{ detailForm.remark }}</div>
            </tmeplate>
            <template v-if="detailForm.annexList?.length">
              <div class="color9 mb12">图片附件</div>
              <div class="file-image">
                <el-image
                  v-for="(src, index) of detailForm.annexList"
                  :key="index"
                  :src="src"
                  :preview-src-list="detailForm.annexList"
                ></el-image>
              </div>
            </template>
          </div>
        </div>
        <div class="handle-btn" v-if="detailForm.status != 4">
          <el-button class="n-btn red" @click="$emit('cancelOrder', detailForm)"
            >撤单</el-button
          >
          <el-button class="n-btn" @click="$emit('editOrder', detailForm)"
            >编辑</el-button
          >
          <template v-if="detailForm.status != 3">
            <el-button
              type="primary"
              class="p-btn"
              @click="$emit('handleReceipt', detailForm)"
              >收款</el-button
            >
            <el-button
              type="primary"
              class="p-btn"
              @click="$emit('accountOrder', detailForm)"
              >结单</el-button
            >
          </template>
        </div>
      </template>
    </el-drawer>
    <!--报备带看详情-->
    <report-detail
      :visible.sync="reportDetailVisible"
      :report-id="detailForm?.reportInfo?.id"
    ></report-detail>
  </div>
</template>

<script>
import { dealOrderDetail } from "@/api/order";
import ReportDetail from "../../../report/_components/reportDetail";
export default {
  components: {
    ReportDetail
  },
  props: {
    detailId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      detailForm: {},
      statusMap: {
        1: "待结单",
        2: "结单中",
        3: "已结单",
        4: "已撤单"
      },
      iconMap: {
        1: { bg: "#FFF1F2", color: "icon-daijiedan" },
        2: { bg: "#FFF8EA", color: "icon-jiedanzhong" },
        3: { bg: "#ECFFF7", color: "icon-yijiedan" },
        4: { bg: "#EEEEEE", color: "icon-guanbi" }
      },
      reportDetailVisible: false // 报备带看详情抽屉显示
    };
  },
  methods: {
    // 打开弹框获取详情
    async handleOpen() {
      const res = await dealOrderDetail(this.detailId);
      if (res) {
        this.detailForm = res;
      }
    },
    closeDrawer() {
      this.detailForm = {};
      this.$emit("update:visible", false);
    },
    // 编辑收款记录
    editReceipt(item) {
      const { estateName, houseInfo, dealNumber } = this.detailForm;
      const row = {
        estateName: estateName,
        houseInfo: houseInfo,
        dealNumber: dealNumber,
        paymentDate: this.$format.date(item.paymentDate),
        amount: item.amount,
        remark: item.remark,
        id: item.id
      };
      this.$emit("handleReceipt", row, item.id);
    }
  }
};
</script>

<style scoped lang="scss">
.make-order-detail {
  width: 427px;
  min-height: 100%;
  background: #f2f2f2;
  padding-bottom: 71px;
  .line {
    width: 100%;
    height: 27px;
    background: $red;
    &.line-2 {
      background: #a66400;
    }
    &.line-3 {
      background: $green;
    }
    &.line-4 {
      background: $color6;
    }
  }
  .base-info {
    padding: 15px 25px;
    border-radius: 5px 5px 0 0;
    margin-top: -21px;
    background: #fff;
    margin-bottom: 10px;
    .head {
      @include flex-ycb;
      .mb3 {
        margin-bottom: 3px;
      }
      .el-icon-close {
        font-size: 24px;
        color: #999;
      }
    }
    .status {
      margin: 20px auto;
      text-align: center;
      font-size: 15px;
      color: $red;
      min-height: 80px;
      .status-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 auto;
        @include flex-xyc;
        i {
          font-size: 23px;
        }
      }
      & > div:nth-child(2) {
        margin: 6px 0 3px;
      }
      &.status-2 {
        color: #a66400;
      }
      &.status-3 {
        color: $green;
      }
      &.status-4 {
        color: $color6;
      }
    }
    .project {
      margin-bottom: 20px;
      .p-name {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 4px;
        word-break: break-all;
        white-space: pre-line;
      }
    }
    .label-box {
      & > div:not(.border) {
        margin-bottom: 11px;
        @include flex-ycb;
        & > div:first-child {
          color: #999;
          min-width: 80px;
          flex: 1;
        }
        & > div:last-child {
          word-break: break-all;
          white-space: pre-line;
        }
        .color566 {
          color: #566891;
        }
      }
      .border {
        border-bottom: solid 1px #eee;
        margin: 15px 0;
      }
    }
  }
  .price-total {
    margin: 20px 0 10px;
    @include flex-yce;
    .price {
      margin-left: 8px;
      span {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .collect-record {
    padding: 25px 25px 15px;
    background: #fff;
    margin-bottom: 10px;
    .record-list {
      border-bottom: dashed 1px #eee;
      margin-bottom: 16px;
      padding-bottom: 16px;
      .r-list-info {
        @include flex-ycb;
        .color9 {
          line-height: 22px;
          margin-right: 10px;
          .flex {
            display: flex;
          }
          span {
            min-width: 78px;
            display: inline-block;
          }
          .r-remark {
            word-break: break-all;
            white-space: pre-line;
          }
        }
        i {
          font-size: 18px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .make-remark {
    padding: 25px;
    background: #fff;
    .remark-text {
      word-wrap: break-word;
      white-space: pre-wrap;
    }
    .file-image {
      @include flex-w;
      .el-image {
        width: 75px;
        height: 75px;
        margin-right: 9px;
        margin-bottom: 9px;
        border-radius: 4px;
      }
    }
  }
  &.s-end {
    .p-name,
    .label-box > div > div:last-child,
    .price-total,
    .remark-text {
      opacity: 0.6;
    }
  }
}
.handle-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 427px;
  height: 71px;
  background: #fff;
  box-shadow: 2px 0 5px #eee;
  padding: 0 24px;
  @include flex-xyc;
  .el-button {
    padding: 11px 20px;
  }
  .n-btn {
    min-width: 72px;
    flex: 1;
    background: #f2f2f2;
    border: none;
  }
  .p-btn {
    width: 100px;
  }
  .el-button + .el-button {
    margin-left: 13px;
  }
}
</style>
