var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "title": _vm.editId ? '编辑收款' : '创建收款',
      "width": "480px",
      "close-on-click-modal": false
    },
    on: {
      "open": _vm.open,
      "close": _vm.cancel
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px",
      "size": "medium"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "成交订单:",
      "prop": "orderNumber",
      "required": ""
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": ""
    },
    model: {
      value: _vm.showOrderNumber,
      callback: function callback($$v) {
        _vm.showOrderNumber = $$v;
      },
      expression: "showOrderNumber"
    }
  }), !_vm.editId ? _c('div', {
    staticClass: "price-tip"
  }, [_vm._v(" 未结金额"), _c('span', {
    staticClass: "red"
  }, [_vm._v("¥"), _vm.detail.amountWait && _vm.detail.amountWait >= 0 ? [_vm._v(_vm._s(_vm._f("toThousands")(_vm.detail.amountWait)))] : [_vm._v("0")]], 2), _vm._v("已结金额"), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.detail.amountEnd || 0)))])]) : _vm._e(), _c('el-input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "readonly": ""
    },
    model: {
      value: _vm.formData.orderNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "orderNumber", $$v);
      },
      expression: "formData.orderNumber"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "佣金金额:",
      "prop": "amount",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.commissionAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "commissionAmount", $$v);
      },
      expression: "formData.commissionAmount"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "现金奖金额:",
      "prop": "amount",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.cashPrizeAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "cashPrizeAmount", $$v);
      },
      expression: "formData.cashPrizeAmount"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "合计收款:",
      "prop": "amount",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "disabled": ""
    },
    model: {
      value: _vm.formData.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "amount", $$v);
      },
      expression: "formData.amount"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "收款日期:",
      "prop": "paymentDate",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "placeholder": "选择日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.formData.paymentDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "paymentDate", $$v);
      },
      expression: "formData.paymentDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "备注说明:",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": "4",
      "resize": "none",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.btn_loading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }