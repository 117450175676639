var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab-page-container"
  }, [_c('el-tabs', {
    staticClass: "fm-tabs-card",
    attrs: {
      "type": "card"
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c('el-tab-pane', {
      key: item.component,
      attrs: {
        "label": item.name,
        "name": "".concat(index)
      }
    }, [_c(_vm.tabs[_vm.activeName].component, {
      tag: "component",
      attrs: {
        "estateList": _vm.estateList,
        "mechanismCascadeList": _vm.mechanismCascadeList
      }
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }