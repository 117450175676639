<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    title="编辑订单"
    width="710px"
    top="10px"
    :close-on-click-modal="false"
    @open="open"
    @close="cancel"
  >
    <el-form :model="formData" label-width="86px" ref="form" size="medium">
      <div class="form-module">
        <div class="title">基本信息</div>
        <div class="item-box">
          <el-form-item
            label="成交项目:"
            prop="estateId"
            :rules="[$formRules.required()]"
          >
            <el-select
              class="f-remote-select"
              v-model="formData.estateId"
              clearable
              filterable
              remote
              reserve-keyword
              placeholder="请选择"
              @change="changeEstate"
              :remote-method="remoteEstate"
              :loading="estateLoading"
            >
              <el-option
                v-for="item in estateList"
                :value="item.estateId"
                :label="item.name"
                :key="item.estateId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="成交金额:"
            prop="amount"
            :rules="[$formRules.required(), $formRules.m0m89p2]"
          >
            <el-input placeholder="请输入" v-model="formData.amount">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="成交日期:"
            prop="dealDate"
            :rules="[$formRules.required()]"
          >
            <el-date-picker
              v-model="formData.dealDate"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="成交房源:" prop="houseInfo">
            <el-input
              placeholder="请输入"
              v-model="formData.houseInfo"
            ></el-input>
          </el-form-item>
          <el-form-item label="报备带看:" prop="reportId">
            <el-select
              class="f-remote-select"
              v-model="formData.reportId"
              clearable
              filterable
              remote
              reserve-keyword
              placeholder="请选择"
              @change="changeReport"
              :remote-method="remoteReport"
              :loading="reportLoading"
            >
              <el-option
                v-for="item in reportArr"
                :value="item.id"
                :label="item.value"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="建筑面积:"
            prop="floorSpace"
            :rules="[$formRules.m0m89p2]"
          >
            <el-input placeholder="请输入" v-model="formData.floorSpace">
              <template slot="append">㎡</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="合作渠道:"
            prop="mechanismId"
            :rules="[$formRules.required()]"
          >
            <el-select
              class="f-remote-select"
              v-model="formData.mechanismId"
              clearable
              filterable
              remote
              reserve-keyword
              placeholder="请选择"
              :remote-method="remoteMechanism"
              :loading="companyLoading"
              :disabled="!!formData.reportId"
              @change="changeCompany"
            >
              <el-option
                v-for="item in companyArr"
                :value="item.id"
                :label="item.name"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="经纪人:"
            prop="agentPhone"
            :rules="[$formRules.tel]"
            class="broker-item"
          >
            <el-input
              placeholder="请输入"
              v-model="formData.agentName"
            ></el-input>
            <el-input
              placeholder="请输入"
              v-model="formData.agentPhone"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="form-module">
        <div class="title">客户信息</div>
        <div class="user-list" v-if="formData.customerList?.length">
          <div>
            <div>客户姓名</div>
            <div>联系电话</div>
            <div>备注说明</div>
          </div>
          <div v-for="(item, index) in formData.customerList" :key="index">
            <el-input
              v-model="item.customerName"
              placeholder="请输入"
              size="medium"
            ></el-input>
            <el-input
              v-model="item.customerPhone"
              placeholder="请输入"
              size="medium"
              maxlength="11"
              @change="validatePhone(item, item.customerPhone)"
              :class="{ 'is-error': item.verify }"
            ></el-input>
            <el-input
              v-model="item.remark"
              placeholder="请输入"
              size="medium"
            ></el-input>
            <i
              class="el-icon-remove-outline"
              @click="deleteUser(index)"
              v-if="formData.customerList.length > 1"
            ></i>
          </div>
        </div>
        <div class="add-user" @click="addUser">
          <i class="el-icon-plus"></i>添加客户
        </div>
      </div>
      <div class="form-module">
        <div class="title">佣金结算</div>
        <div class="item-box">
          <el-form-item
            label="佣金收入:"
            prop="settlementCommission"
            :rules="[$formRules.required(), $formRules.m0m89p2]"
          >
            <el-input
              placeholder="请输入"
              v-model="formData.settlementCommission"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="现金奖励:"
            prop="settlementCashPrize"
            :rules="[$formRules.m0m89p2]"
          >
            <el-input
              placeholder="请输入"
              v-model="formData.settlementCashPrize"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="其他收入:"
            prop="settlementOtherIncome"
            :rules="[$formRules.m0m89p2]"
          >
            <el-input
              placeholder="请输入"
              v-model="formData.settlementOtherIncome"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="合计应结:" prop="amountConclusion">
            <span class="red bold fs15">{{ calcAmount | toThousands }}</span>
          </el-form-item>
        </div>
      </div>
      <div class="form-module">
        <div class="title">更多信息</div>
        <div class="more-info">
          <div class="mb20">
            <div>图片附件</div>
            <div class="file-list-box">
              <el-upload
                action=""
                :accept="accept"
                class="upload-images mr10"
                list-type="picture-card"
                multiple
                :auto-upload="false"
                :show-file-list="false"
                :file-list="fileList"
                :on-change="imgChangeNUpload"
              >
                <div slot="default" class="default-upload-btn">
                  <i class="el-icon-plus"></i>
                </div>
              </el-upload>
              <div
                class="file-list"
                v-for="(item, index) in fileList"
                :key="index"
              >
                <el-image fit="cover" :src="item.url">
                  <i slot="error" class="el-icon-picture"></i>
                </el-image>
                <span class="upload-actions">
                  <span @click="handlePreview(item)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span @click="handleRemove(item)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div>
            <div>备注说明</div>
            <el-input
              type="textarea"
              rows="3"
              resize="none"
              placeholder="请输入"
              v-model="formData.remark"
            ></el-input>
          </div>
        </div>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" :loading="btn_loading" @click="submit"
        >提交</el-button
      >
    </div>
    <el-dialog
      title="大图预览"
      :visible.sync="previewImageVisible"
      append-to-body
    >
      <img width="100%" :src="previewImageUrl" alt="" />
    </el-dialog>
  </el-dialog>
</template>

<script>
import mixin from "@/components/upload/mixin.js";
import { dealOrderDetail, dealOrderEdit, mechanismBranch } from "@/api/order";
import { getReportList } from "@/api/distribution";
import { pagedLinkedHouse } from "@/api/estateManage";
export default {
  mixins: [mixin],
  props: {
    detailId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      formData: {
        estateId: "", //成交项目
        amount: "", //成交金额
        dealDate: null, //成交日期
        houseInfo: "", //成交房源
        reportId: "", //报备带看
        floorSpace: "", //建筑面积
        mechanismIdReal: "", //合作渠道公司id
        mechanismId: "", //合作渠道分部id
        agentName: "", //经纪人姓名
        agentPhone: "", //经纪人电话
        customerList: [], //客户信息
        settlementCommission: "", //佣金收入
        settlementCashPrize: "", //现金奖励
        settlementOtherIncome: "", //其他收入
        amountConclusion: 0, //合计应结
        remark: "" //备注说明
      },
      accept: "image/gif, image/jpeg, image/jpg, image/png, image/webp",
      estateList: [],
      estateLoading: false,
      reportArr: [],
      reportLoading: false,
      companyArr: [], //渠道公司列表
      companyLoading: false,
      props: {
        label: "name",
        value: "id",
        emitPath: false
      },
      btn_loading: false
    };
  },
  computed: {
    // 合计应结
    calcAmount() {
      return parseFloat(
        (
          parseFloat(this.formData.settlementCommission || 0) +
          parseFloat(this.formData.settlementCashPrize || 0) +
          parseFloat(this.formData.settlementOtherIncome || 0)
        ).toFixed(2)
      );
    },
    // 客户信息校验
    userError() {
      let status = this.formData.customerList.some(item => item.verify);
      return status ? "客户联系电话格式不正确" : "";
    }
  },
  methods: {
    // 打开弹框获取详情
    async open() {
      const res = await dealOrderDetail(this.detailId);
      if (res) {
        this.formData = res;
        if (!res.customerList.length)
          this.formData.customerList = [
            { customerName: "", customerPhone: "", remark: "" }
          ];
        this.fileList =
          res.annexList?.map(item => {
            return { url: item };
          }) || [];
        this.getEstateList(res.estateName);
        // 有客户信息请求报备带看列表
        if (res.reportInfo?.customerName) {
          this.reportList(res.reportInfo.customerName);
        }
        // 有渠道信息请求渠道公司列表
        if (res.mechanismId == -1) {
          this.companyArr = [{ name: "自然到访", id: -1 }];
        } else {
          this.mechanismList(res.mechanismName);
        }
      }
    },
    cancel() {
      this.$refs.form.resetFields();
      this.formData.customerList = [];
      this.fileList = [];
      this.formData.remark = "";
      this.$emit("update:visible", false);
    },
    // 手机号验证正则
    validatePhone(item, phone) {
      const phoneRegex = /^1[3456789]\d{9}$/;
      if (phone && !phoneRegex.test(phone)) {
        this.$set(item, "verify", true);
      } else {
        this.$set(item, "verify", false);
      }
    },
    // 获取成交项目列表
    async getEstateList(keyword) {
      const res = await pagedLinkedHouse({
        pageNum: 1,
        pageSize: 1000,
        keyword: keyword
      });
      if (res) {
        this.estateList = res.list;
      }
      this.estateLoading = false;
    },
    // 远程搜索成交项目
    remoteEstate(query) {
      if (query !== "") {
        this.estateLoading = true;
        this.getEstateList(query);
      } else {
        this.estateList = [];
      }
    },
    // 获取报备带看人员列表
    async reportList(keyword) {
      const res = await getReportList({
        pageNum: 1,
        pageSize: 5000,
        reportStates: [2], //已带看
        estates: [this.formData.estateId],
        keyword: keyword
      });
      if (res) {
        this.reportArr = res.list.map(item => {
          return {
            id: item.id,
            value: `${item.customerName} ${item.customerTelephone}`,
            company: item.company,
            companyId: item.companyId,
            branchId: item.branchId
          };
        });
      }
      this.reportLoading = false;
    },
    // 远程搜索报备带看
    remoteReport(query) {
      if (query !== "") {
        this.reportLoading = true;
        this.reportList(query);
      } else {
        this.reportArr = [];
      }
    },
    // 获取渠道公司列表
    async mechanismList(keyword) {
      const res = await mechanismBranch({
        pageNum: 1,
        pageSize: 1000,
        targetType: 0,
        status: 1,
        keyword: keyword
      });
      if (res) {
        // 将公司子集分部列表扁平化
        const flattenArray = (array, prefix = "") => {
          return array.reduce((result, item) => {
            const newItem = {
              id: item.id,
              name: prefix + item.name,
              parentId: item.parentId
            };
            result.push(newItem);
            if (item.children) {
              result.push(...flattenArray(item.children, newItem.name + "-"));
            }
            return result;
          }, []);
        };
        this.companyArr = flattenArray(res);
        if ("自然到访".indexOf(keyword) > -1)
          this.companyArr.unshift({ name: "自然到访", id: -1 });
      }
      this.companyLoading = false;
    },
    // 远程搜索合作渠道
    remoteMechanism(query) {
      if (query !== "") {
        this.companyLoading = true;
        this.mechanismList(query);
      } else {
        this.companyArr = [];
      }
    },
    // 成交项目改变重新请求报备带看
    changeEstate(e) {
      if (e) this.formData.reportId = "";
      else this.estateList = [];
    },
    // 选择报备带看赋值合作渠道
    async changeReport(e) {
      if (!e) {
        this.reportArr = [];
        return;
      }
      let obj = this.reportArr.filter(item => item.id == e);
      await this.mechanismList(obj.company);
      this.formData.mechanismId = obj[0].branchId
        ? obj[0].branchId
        : obj[0].companyId;
    },
    // 清空合作渠道
    changeCompany(e) {
      if (!e) this.companyArr = [];
    },
    // 添加客户
    addUser() {
      this.formData.customerList.push({
        customerName: "",
        customerPhone: "",
        remark: ""
      });
    },
    // 删除客户
    deleteUser(index) {
      this.formData.customerList.splice(index, 1);
    },
    // 提交
    submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.userError) {
            this.$message.error(this.userError);
            return;
          }
          this.btn_loading = true;
          let params = {
            ...this.formData,
            customerList: this.formData.customerList?.filter(
              item => item.customerName || item.customerPhone || item.remark
            ),
            annexList: this.fileList?.map(item => item.url)
          };
          const res = await dealOrderEdit(params);
          if (res) {
            this.$message.success("编辑成功!");
            this.cancel();
            this.$emit("success");
          }
          this.btn_loading = false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e4e7ed;
  }
  .el-dialog__body {
    padding: 22px 40px 10px 20px;
  }
  .el-dialog__footer {
    border-top: 1px solid #e4e7ed;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .el-form-item {
    margin-bottom: 16px;
  }
  .el-input {
    width: 100%;
  }
}
.form-module {
  &:not(:last-child) {
    border-bottom: solid 1px #eee;
    margin-bottom: 14px;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
  }
  .item-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    ::v-deep .broker-item .el-form-item__content {
      display: flex;
      .el-input:first-child {
        width: 70%;
        margin-right: 8px;
      }
    }
  }
  .user-list {
    & > div {
      display: flex;
      align-items: center;
      &:first-child {
        font-size: 12px;
        color: #7f7f7f;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      & > div {
        margin-right: 12px;
        line-height: 16px;
      }
      & > div:first-child {
        width: 100px;
      }
      & > div:nth-child(2) {
        width: 190px;
      }
      & > div:nth-child(3) {
        width: 310px;
      }
      i {
        font-size: 21px;
        color: red;
        cursor: pointer;
      }
      ::v-deep .is-error .el-input__inner {
        border-color: #f56c6c;
      }
    }
  }
  .add-user {
    @include flex-yc;
    line-height: 22px;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 14px;
    cursor: pointer;
    display: inline-block;
    i {
      margin-right: 5px;
    }
  }
  .more-info {
    margin-bottom: 20px;
    & > div > div:first-child {
      margin-bottom: 12px;
    }
    .file-list-box {
      display: flex;
      flex-wrap: wrap;
      ::v-deep .upload-images {
        .el-upload,
        .el-upload-list__item {
          width: 75px;
          height: 75px;
          border: none;
        }
        .default-upload-btn {
          width: 100%;
          height: 100%;
          line-height: 1;
          background: #f2f2f2;
          border-radius: 4px;
          @include flex-xyc;
        }
      }
      .file-list {
        width: 75px;
        height: 75px;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        .el-image {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          @include flex-xyc;
        }
        .upload-actions {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          cursor: default;
          text-align: center;
          color: #fff;
          opacity: 0;
          font-size: 20px;
          background-color: rgba(0, 0, 0, 0.5);
          -webkit-transition: opacity 0.3s;
          transition: opacity 0.3s;
          border-radius: 4px;
          @include flex-xyc;
          &:hover {
            opacity: 1;
          }
          span {
            cursor: pointer;
            .el-icon-delete {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}
</style>
