<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="editId ? '编辑收款' : '创建收款'"
    width="480px"
    :close-on-click-modal="false"
    @open="open"
    @close="cancel"
  >
    <el-form :model="formData" label-width="100px" ref="form" size="medium">
      <el-form-item label="成交订单:" prop="orderNumber" required>
        <el-input readonly v-model="showOrderNumber"></el-input>
        <div class="price-tip" v-if="!editId">
          未结金额<span class="red"
            >¥<template v-if="detail.amountWait && detail.amountWait >= 0">{{
              detail.amountWait | toThousands
            }}</template
            ><template v-else>0</template></span
          >已结金额<span>¥{{ (detail.amountEnd || 0) | toThousands }}</span>
        </div>
        <el-input
          readonly
          v-model="formData.orderNumber"
          style="display: none"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="佣金金额:"
        prop="amount"
        :rules="[$formRules.required(), $formRules.m0m89p2]"
      >
        <el-input placeholder="请输入" v-model="formData.commissionAmount">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="现金奖金额:"
        prop="amount"
        :rules="[$formRules.required(), $formRules.m0m89p2]"
      >
        <el-input placeholder="请输入" v-model="formData.cashPrizeAmount">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="合计收款:"
        prop="amount"
        :rules="[$formRules.required(), $formRules.m0m89p2]"
      >
        <el-input placeholder="请输入" disabled v-model="formData.amount">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="收款日期:"
        prop="paymentDate"
        :rules="[$formRules.required()]"
      >
        <el-date-picker
          v-model="formData.paymentDate"
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注说明:" prop="remark">
        <el-input
          type="textarea"
          rows="4"
          resize="none"
          placeholder="请输入"
          v-model="formData.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" :loading="btn_loading" @click="submit"
        >提交</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { createReceipt, editReceipt } from "@/api/order";
export default {
  props: {
    editId: {
      type: Number,
      default: null
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formData: {
        orderNumber: "",
        commissionAmount: "", //佣金金额
        cashPrizeAmount: "", //现金金额
        amount: "", //合计收款金额
        paymentDate: null, //收款日期
        remark: ""
      },
      btn_loading: false
    };
  },
  computed: {
    // 订单信息
    showOrderNumber() {
      const { estateName, houseInfo, dealNumber } = this.detail;
      return `${estateName} ${houseInfo} （NO.${dealNumber}）`;
    }
  },
  watch: {
    formData: {
      handler(val) {
        this.formData.amount =
          Number(val.commissionAmount) + Number(val.cashPrizeAmount);
      },
      deep: true
    }
  },
  methods: {
    open() {
      this.formData.orderNumber = this.detail.dealNumber;
      if (this.editId) Object.assign(this.formData, this.detail);
    },
    cancel() {
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const settle = this.editId
            ? false
            : parseFloat(this.detail.amountEnd || 0) +
                parseFloat(this.formData.amount) >=
              this.detail.amountConclusion
            ? true
            : false;
          let params = {
            amount: Number(this.formData.amount),
            commissionAmount: Number(this.formData.commissionAmount), //佣金金额
            cashPrizeAmount: Number(this.formData.cashPrizeAmount), //现金金额
            paymentDate: this.formData.paymentDate,
            remark: this.formData.remark,
            settlement: settle //是否结单
          };
          if (this.editId) params.id = this.editId;
          else params.dealId = this.detail.id;
          if (settle) {
            this.$confirm("该订单已完成全部结款，是否需要结单？", "结单提示", {
              confirmButtonText: "结单",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                this.handleReceipt(params, "结单成功");
              })
              .catch(() => {
                params.settlement = false;
                this.btn_loading = true;
                this.handleReceipt(params, "收款成功");
              });
          } else {
            this.btn_loading = true;
            this.handleReceipt(params, "收款成功");
          }
        }
      });
    },
    // 请求收款接口
    async handleReceipt(params, msg) {
      const res = this.editId
        ? await editReceipt(params)
        : await createReceipt(params);
      if (res) {
        this.$message.success(msg);
        this.cancel();
        this.$emit("success");
      }
      this.btn_loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e4e7ed;
  }
  .el-dialog__footer {
    border-top: 1px solid #e4e7ed;
    padding-top: 14px;
  }
  .el-input__inner[readonly] {
    background: #f2f2f2;
  }
  .el-date-editor.el-input {
    width: 100%;
  }
}
.price-tip {
  font-size: 13px;
  line-height: 13px;
  margin-top: 10px;
  color: #999;
  span {
    margin: 0 8px;
  }
}
</style>
