var render = function render(){
  var _vm$detailForm$report, _vm$detailForm$custom, _vm$detailForm$paymen, _vm$detailForm$annexL, _vm$detailForm$annexL2, _vm$detailForm, _vm$detailForm$report2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-drawer', _vm._g(_vm._b({
    attrs: {
      "with-header": false,
      "size": "427"
    },
    on: {
      "open": _vm.handleOpen,
      "close": _vm.closeDrawer
    }
  }, 'el-drawer', _vm.$attrs, false), _vm.$listeners), [_vm.detailForm.id ? [_c('div', {
    staticClass: "make-order-detail",
    class: {
      's-end': _vm.detailForm.status == 4
    }
  }, [_c('div', {
    staticClass: "line",
    class: 'line-' + _vm.detailForm.status
  }), _c('div', {
    staticClass: "base-info"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', [_c('div', {
    staticClass: "fs16 mb3"
  }, [_vm._v("NO." + _vm._s(_vm.detailForm.dealNumber))]), _c('div', {
    staticClass: "fs13 color9"
  }, [_vm._v(" 报单 " + _vm._s(_vm.detailForm.inUserName) + " " + _vm._s(_vm._f("formatDate")(_vm.detailForm.inDate, 12)) + " ")])]), _c('i', {
    staticClass: "el-icon-close",
    on: {
      "click": _vm.closeDrawer
    }
  })]), _c('div', {
    staticClass: "status",
    class: 'status-' + _vm.detailForm.status
  }, [_c('div', {
    staticClass: "status-icon",
    style: 'background:' + _vm.iconMap[_vm.detailForm.status].bg
  }, [_c('i', {
    staticClass: "iconfont",
    class: _vm.iconMap[_vm.detailForm.status].color
  })]), _c('div', [_vm._v(_vm._s(_vm.statusMap[_vm.detailForm.status]))]), _vm.detailForm.status == 2 ? _c('div', {
    staticClass: "fs14 color9"
  }, [_vm._v(" 已结金额 ¥" + _vm._s(_vm._f("toThousands")(_vm.detailForm.amountEnd)) + "，未结金额 ¥"), _vm.detailForm.amountWait && _vm.detailForm.amountWait >= 0 ? [_vm._v(_vm._s(_vm._f("toThousands")(_vm.detailForm.amountWait)))] : [_vm._v("0")]], 2) : _vm._e()]), _c('div', {
    staticClass: "project"
  }, [_c('div', {
    staticClass: "p-name"
  }, [_vm._v(" " + _vm._s(_vm.detailForm.estateName) + " " + _vm._s(_vm.detailForm.houseInfo) + " " + _vm._s(_vm.detailForm.floorSpace ? _vm.detailForm.floorSpace + "㎡" : "") + " ")]), _c('div', {
    staticClass: "color9"
  }, [_vm._v("成交项目")])]), _c('div', {
    staticClass: "label-box"
  }, [_c('div', [_c('div', [_vm._v("成交金额")]), _c('div', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.detailForm.amount || 0)) + "元")])]), _c('div', [_c('div', [_vm._v("成交日期")]), _c('div', [_vm._v(_vm._s(_vm._f("formatDate")(_vm.detailForm.dealDate, 5)))])]), _c('div', [_c('div', [_vm._v("建筑面积")]), _c('div', [_vm._v(" " + _vm._s(_vm.detailForm.floorSpace ? _vm.detailForm.floorSpace + "㎡" : "-") + " ")])]), _c('div', [_c('div', [_vm._v("报备带看")]), _vm.detailForm.reportInfo ? _c('div', {
    staticClass: "color566 pointer",
    on: {
      "click": function click($event) {
        _vm.reportDetailVisible = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.detailForm.reportInfo.customerName) + " " + _vm._s((_vm$detailForm$report = _vm.detailForm.reportInfo.reportTelephoneReqVos[0]) === null || _vm$detailForm$report === void 0 ? void 0 : _vm$detailForm$report.telephone) + " ")]) : _c('div', [_vm._v("-")])]), _c('div', [_c('div', [_vm._v("合作渠道")]), _vm.detailForm.mechanismId == -1 ? _c('div', [_vm._v("自然到访")]) : _vm.detailForm.mechanismName ? _c('div', [_vm._v(" " + _vm._s(_vm._f("link")([_vm.detailForm.mechanismName, _vm.detailForm.mechanismStoreName], "-")) + " ")]) : _c('div', [_vm._v("-")])]), _c('div', [_c('div', [_vm._v("经纪人姓名")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.agentName || "-"))])]), _c('div', [_c('div', [_vm._v("经纪人电话")]), _vm.detailForm.agentPhone ? _c('div', [_vm._v(" " + _vm._s(_vm._f("formatPhone")(_vm.detailForm.agentPhone)) + " ")]) : _c('div', [_vm._v("-")])]), _c('div', {
    staticClass: "border"
  }), (_vm$detailForm$custom = _vm.detailForm.customerList) !== null && _vm$detailForm$custom !== void 0 && _vm$detailForm$custom.length ? [_vm._l(_vm.detailForm.customerList, function (item, index) {
    return _c('dl', {
      key: index,
      staticClass: "label-box"
    }, [_c('div', [_c('div', [_vm._v("客户姓名")]), _c('div', [_vm._v(_vm._s(item.customerName || "-"))])]), _c('div', [_c('div', [_vm._v("联系电话")]), item.customerPhone ? _c('div', [_vm._v(" " + _vm._s(_vm._f("formatPhone")(item.customerPhone)) + " ")]) : _c('div', [_vm._v("-")])])]);
  }), _c('div', {
    staticClass: "border"
  })] : _vm._e(), _c('div', [_c('div', [_vm._v("佣金")]), _c('div', [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.detailForm.settlementCommission || 0)) + " ")])]), _c('div', [_c('div', [_vm._v("现金奖")]), _c('div', [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.detailForm.settlementCashPrize || 0)) + " ")])]), _c('div', [_c('div', [_vm._v("其他收入")]), _c('div', [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.detailForm.settlementOtherIncome || 0)) + " ")])])], 2), _c('div', {
    staticClass: "price-total"
  }, [_c('span', {
    staticClass: "color9"
  }, [_vm._v("合计应结")]), _c('span', {
    staticClass: "price"
  }, [_vm._v("¥"), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.detailForm.amountConclusion || 0)))])])])]), (_vm.detailForm.status == 2 || _vm.detailForm.status == 3) && (_vm$detailForm$paymen = _vm.detailForm.paymentRecords) !== null && _vm$detailForm$paymen !== void 0 && _vm$detailForm$paymen.length ? _c('div', {
    staticClass: "collect-record"
  }, [_c('div', {
    staticClass: "color9 mb20"
  }, [_vm._v("收款记录")]), _vm._l(_vm.detailForm.paymentRecords, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "record-list"
    }, [_c('div', {
      staticClass: "fs16 bold mb12"
    }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(item.amount)))]), _c('div', {
      staticClass: "r-list-info"
    }, [_c('div', {
      staticClass: "color9"
    }, [_c('div', [_c('span', [_vm._v("收款日期：")]), _vm._v(_vm._s(_vm._f("formatDate")(item.paymentDate, 2)) + " ")]), item.remark ? _c('div', {
      staticClass: "flex"
    }, [_c('span', [_vm._v("备注说明：")]), _c('span', {
      staticClass: "r-remark"
    }, [_vm._v(_vm._s(item.remark))])]) : _vm._e(), _c('div', [_c('span', [_vm._v("录入人：")]), _vm._v(_vm._s(item.inUserName))]), _c('div', [_c('span', [_vm._v("录入时间：")]), _vm._v(_vm._s(_vm._f("formatDate")(item.inDate, 9)) + " ")])]), _c('i', {
      staticClass: "el-icon-edit-outline",
      on: {
        "click": function click($event) {
          return _vm.editReceipt(item);
        }
      }
    })])]);
  }), _c('div', {
    staticClass: "price-total"
  }, [_vm.detailForm.status == 2 ? [_c('span', {
    staticClass: "color9"
  }, [_vm._v("未结金额")]), _c('span', {
    staticClass: "price mr10"
  }, [_vm._v("¥"), _vm.detailForm.amountWait && _vm.detailForm.amountWait >= 0 ? _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.detailForm.amountWait)))]) : _c('span', [_vm._v("0")])])] : _vm._e(), _c('span', {
    staticClass: "color9"
  }, [_vm._v(_vm._s(_vm.detailForm.status == 2 ? "已结金额" : "实结金额"))]), _c('span', {
    staticClass: "price red"
  }, [_vm._v("¥"), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.detailForm.amountEnd)))])])], 2)], 2) : _vm._e(), _vm.detailForm.remark || (_vm$detailForm$annexL = _vm.detailForm.annexList) !== null && _vm$detailForm$annexL !== void 0 && _vm$detailForm$annexL.length ? _c('div', {
    staticClass: "make-remark"
  }, [_vm.detailForm.remark ? _c('tmeplate', [_c('div', {
    staticClass: "color9 mb8"
  }, [_vm._v("备注说明")]), _c('div', {
    staticClass: "fs15 mb16 remark-text"
  }, [_vm._v(_vm._s(_vm.detailForm.remark))])]) : _vm._e(), (_vm$detailForm$annexL2 = _vm.detailForm.annexList) !== null && _vm$detailForm$annexL2 !== void 0 && _vm$detailForm$annexL2.length ? [_c('div', {
    staticClass: "color9 mb12"
  }, [_vm._v("图片附件")]), _c('div', {
    staticClass: "file-image"
  }, _vm._l(_vm.detailForm.annexList, function (src, index) {
    return _c('el-image', {
      key: index,
      attrs: {
        "src": src,
        "preview-src-list": _vm.detailForm.annexList
      }
    });
  }), 1)] : _vm._e()], 2) : _vm._e()]), _vm.detailForm.status != 4 ? _c('div', {
    staticClass: "handle-btn"
  }, [_c('el-button', {
    staticClass: "n-btn red",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancelOrder', _vm.detailForm);
      }
    }
  }, [_vm._v("撤单")]), _c('el-button', {
    staticClass: "n-btn",
    on: {
      "click": function click($event) {
        return _vm.$emit('editOrder', _vm.detailForm);
      }
    }
  }, [_vm._v("编辑")]), _vm.detailForm.status != 3 ? [_c('el-button', {
    staticClass: "p-btn",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('handleReceipt', _vm.detailForm);
      }
    }
  }, [_vm._v("收款")]), _c('el-button', {
    staticClass: "p-btn",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('accountOrder', _vm.detailForm);
      }
    }
  }, [_vm._v("结单")])] : _vm._e()], 2) : _vm._e()] : _vm._e()], 2), _c('report-detail', {
    attrs: {
      "visible": _vm.reportDetailVisible,
      "report-id": (_vm$detailForm = _vm.detailForm) === null || _vm$detailForm === void 0 ? void 0 : (_vm$detailForm$report2 = _vm$detailForm.reportInfo) === null || _vm$detailForm$report2 === void 0 ? void 0 : _vm$detailForm$report2.id
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.reportDetailVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }