var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "filter"
  }, [_c('div', {
    staticClass: "m"
  }, [_c('el-select', {
    staticClass: "mr10",
    attrs: {
      "placeholder": "成交项目",
      "clearable": "",
      "filterable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.estateId,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "estateId", $$v);
      },
      expression: "queryData.estateId"
    }
  }, _vm._l(_vm.estateList, function (item) {
    return _c('el-option', {
      key: item.estateId,
      attrs: {
        "value": item.estateId,
        "label": item.estateName
      }
    });
  }), 1), _c('el-select', {
    staticClass: "type-select",
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.dateType,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "dateType", $$v);
      },
      expression: "queryData.dateType"
    }
  }, [_c('el-option', {
    attrs: {
      "value": 1,
      "label": "收款日期"
    }
  }), _c('el-option', {
    attrs: {
      "value": 2,
      "label": "录入日期"
    }
  })], 1), _c('el-date-picker', {
    attrs: {
      "type": "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      "default-time": ['00:00:00', '23:59:59']
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.date,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "date", $$v);
      },
      expression: "queryData.date"
    }
  })], 1), _c('el-button-group', {
    staticClass: "actions-btn"
  }, [_c('f-table-fields', {
    attrs: {
      "name": "transactionManageTab2",
      "options": _vm.defaultFields
    },
    on: {
      "change": _vm.onFieldsChange
    }
  }, [_c('el-button', {
    attrs: {
      "icon": "iconfont icon-list"
    }
  })], 1), _c('el-button', {
    attrs: {
      "icon": "iconfont icon-refresh"
    },
    on: {
      "click": _vm.getList
    }
  })], 1)], 1), _c('f-table', {
    ref: "table",
    staticClass: "mt20",
    attrs: {
      "border": "",
      "data": _vm.list,
      "columns": _vm.tableColumns
    }
  }), _vm.list.length ? _c('div', {
    staticClass: "total-collect"
  }, [_vm._v(" 合计收款金额："), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.sumData.totalAmount)))]), _vm._v("　 合计佣金金额："), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.sumData.totalCommissionAmount)))]), _vm._v("　 合计现金奖金额："), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.sumData.totalCashPrizeAmount)))])]) : _vm._e(), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getList
    }
  }), _c('receipt-dialog', {
    attrs: {
      "visible": _vm.receiptDialog,
      "editId": _vm.editId,
      "detail": _vm.detail
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.receiptDialog = $event;
      },
      "success": _vm.handleEditSuccess
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }