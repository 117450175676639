<template>
  <div class="content">
    <div class="filter">
      <div class="m">
        <el-select
          v-model="queryData.estateId"
          placeholder="成交项目"
          class="mr10"
          clearable
          filterable
          @change="search"
        >
          <el-option
            v-for="item of estateList"
            :value="item.estateId"
            :label="item.estateName"
            :key="item.estateId"
          ></el-option>
        </el-select>
        <el-select
          v-model="queryData.dateType"
          @change="search"
          class="type-select"
        >
          <el-option :value="1" label="收款日期"></el-option>
          <el-option :value="2" label="录入日期"></el-option>
        </el-select>
        <el-date-picker
          v-model="queryData.date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="search"
        >
        </el-date-picker>
      </div>
      <el-button-group class="actions-btn">
        <f-table-fields
          name="transactionManageTab2"
          :options="defaultFields"
          @change="onFieldsChange"
        >
          <el-button icon="iconfont icon-list"></el-button>
        </f-table-fields>
        <el-button icon="iconfont icon-refresh" @click="getList"></el-button>
      </el-button-group>
    </div>

    <f-table
      ref="table"
      class="mt20"
      border
      :data="list"
      :columns="tableColumns"
    ></f-table>
    <div class="total-collect" v-if="list.length">
      合计收款金额：<span>¥{{ sumData.totalAmount | toThousands }}</span
      >&#12288; 合计佣金金额：<span
        >¥{{ sumData.totalCommissionAmount | toThousands }}</span
      >&#12288; 合计现金奖金额：<span
        >¥{{ sumData.totalCashPrizeAmount | toThousands }}</span
      >
    </div>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
    <!--编辑收款-->
    <receipt-dialog
      :visible.sync="receiptDialog"
      :editId="editId"
      :detail="detail"
      @success="handleEditSuccess"
    ></receipt-dialog>
  </div>
</template>

<script>
import receiptDialog from "../_components/receiptDialog.vue";
import { toThousands } from "@/utils";
import { receiptList, receiptCount, receiptDelete } from "@/api/order";
export default {
  components: { receiptDialog },
  props: {
    estateList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      queryData: {
        pageNum: 1,
        pageSize: 10,
        estateId: null,
        dateType: 1,
        date: null
      },
      dateType: 1,
      total: 0,
      list: [],
      receiptDialog: false, //编辑收款弹框
      editId: null,
      detail: {},
      sumData: {
        totalAmount: 0,
        totalCommissionAmount: 0,
        totalCashPrizeAmount: 0
      },
      // 需要展示的字段
      checkedFields: this.$storage.get("transactionManageTab2TableFields", []),
      // 默认字段选项
      defaultFields: [
        {
          label: "成交项目",
          prop: "estateName",
          checked: true,
          disabled: true
        },
        {
          label: "成交房源",
          prop: "houseInfo",
          checked: true,
          disabled: true
        },
        {
          label: "应结金额",
          prop: "amountConclusion",
          checked: true
        },
        {
          label: "收款金额",
          prop: "amount",
          checked: true
        },
        {
          label: "佣金金额",
          prop: "commissionAmount",
          checked: true
        },
        {
          label: "现金奖金额",
          prop: "cashPrizeAmount",
          checked: true
        },
        {
          label: "收款日期",
          prop: "paymentDate",
          checked: true
        },
        {
          label: "备注说明",
          prop: "remark",
          checked: true
        },
        {
          label: "录入人",
          prop: "inUserName",
          checked: true
        },
        {
          label: "录入时间",
          prop: "inDate",
          checked: true
        },
        {
          label: "操作",
          prop: "operation",
          checked: true,
          disabled: true
        }
      ]
    };
  },
  computed: {
    tableColumns() {
      const defaultColumns = [
        {
          label: "成交项目",
          prop: "estateName"
        },
        {
          label: "成交房源",
          prop: "houseInfo",
          placeholder: "-"
        },
        {
          label: "应结金额",
          prop: "amountConclusion",
          render: ({ row }) => <span>¥{toThousands(row.amountConclusion)}</span>
        },
        {
          label: "收款金额",
          prop: "amount",
          isComma: true,
          isPrice: true,
          class: "red"
        },
        {
          label: "佣金金额",
          prop: "commissionAmount",
          isComma: true,
          isPrice: true
        },
        {
          label: "现金奖金额",
          prop: "cashPrizeAmount",
          isComma: true,
          isPrice: true
        },
        {
          label: "收款日期",
          prop: "paymentDate",
          render: ({ row }) => (
            <span>{this.$format.date(row.paymentDate, 0)}</span>
          )
        },
        {
          label: "备注说明",
          prop: "remark",
          placeholder: "-"
        },
        {
          label: "录入人",
          prop: "inUserName"
        },
        {
          label: "录入时间",
          prop: "inDate",
          render: ({ row }) => <span>{this.$format.date(row.inDate, 12)}</span>
        },
        {
          label: "操作",
          prop: "operation",
          render: ({ row }) => (
            <div>
              <el-link type="primary" onClick={() => this.handleEdit(row)}>
                编辑
              </el-link>
              <span style="padding: 0 10px;color: #aaa;">|</span>
              <el-link type="primary" onClick={() => this.handleDelete(row)}>
                删除
              </el-link>
            </div>
          )
        }
      ];
      const result = [];
      for (const field of this.checkedFields) {
        const columnIndex = defaultColumns.findIndex(c => c.prop === field);
        if (columnIndex > -1) {
          result.push(defaultColumns[columnIndex]);
          // 储存后就移除 减少下次循环次数
          defaultColumns.splice(columnIndex, 1);
        }
      }
      return result;
    }
  },
  created() {
    this.getList();
    this.getCount();
  },
  methods: {
    onFieldsChange({ checkedFields }) {
      this.checkedFields = checkedFields;
    },
    handleEditSuccess() {
      this.getList();
      this.getCount();
    },
    // 筛选
    search() {
      this.queryData.pageNum = 1;
      let date = this.queryData.date;
      if (date) {
        this.queryData.dateStart = date[0];
        this.queryData.dateEnd = date[1];
      } else {
        delete this.queryData.dateStart;
        delete this.queryData.dateEnd;
      }
      this.getList();
    },
    // 获取列表
    async getList() {
      const res = await receiptList(this.queryData);
      if (res) {
        this.total = res.total;
        this.list = res.list;
      }
    },
    // 获取列表统计
    async getCount() {
      const res = await receiptCount(this.queryData);
      if (res) {
        this.sumData = res;
      }
    },
    // 编辑收款
    handleEdit(row) {
      this.receiptDialog = true;
      this.detail = {
        ...row,
        paymentDate: this.$format.date(row.paymentDate)
      };
      this.editId = row.id;
    },
    // 删除
    handleDelete(row) {
      this.$confirm("删除后无法恢复，确定删除该条数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const res = await receiptDelete(row.id);
        if (res) {
          this.$message.success("删除成功!");
          if (this.list.length === 1 && this.queryData.pageNum > 1) {
            this.queryData.pageNum--;
          }
          this.getList();
          this.getCount();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.filter {
  display: flex;
  .m {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 30px;
    row-gap: 10px;
  }
  .el-select {
    width: 135px;
  }
  .el-date-editor {
    width: 240px;
    border-radius: 0 4px 4px 0;
    margin-left: -1px;
  }
  ::v-deep .type-select .el-input__inner {
    border-radius: 4px 0 0 4px;
  }
  .actions-btn {
    display: flex;
    height: 40px;
    ::v-deep {
      .el-popover__reference {
        .el-button {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
.c-remark {
  @include text-clamp(2);
}
.total-collect {
  text-align: right;
  margin: 12px 0 20px;
  span {
    color: $red;
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
