<template>
  <div class="tab-page-container">
    <el-tabs v-model="activeName" type="card" class="fm-tabs-card">
      <el-tab-pane
        v-for="(item, index) in tabs"
        :label="item.name"
        :name="`${index}`"
        :key="item.component"
      >
        <component
          :is="tabs[activeName].component"
          :estateList="estateList"
          :mechanismCascadeList="mechanismCascadeList"
        ></component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import makeOrder from "./_components/makeOrder";
import ledger from "./_components/ledger";
import { selectData } from "@/api/order";
export default {
  components: {
    makeOrder,
    ledger
  },
  data() {
    return {
      tabs: [
        {
          name: "成交订单",
          component: "makeOrder"
        },
        {
          name: "收款台账",
          component: "ledger"
        }
      ],
      activeName: "0",
      estateList: [], //成交项目
      mechanismCascadeList: [] //合作渠道
    };
  },
  created() {
    this.getSelectData();
  },
  methods: {
    // 获取下拉数据
    async getSelectData() {
      const res = await selectData();
      if (res) {
        this.estateList = res.estateList;
        this.mechanismCascadeList = [
          { name: "自然到访", id: -1 },
          ...(res.mechanismCascadeList || [])
        ];
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
