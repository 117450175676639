<template>
  <div class="content">
    <div class="filter">
      <div class="m">
        <el-input
          placeholder="成交单号、申报人"
          v-model="queryData.keywords"
          class="mr10 f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-model="queryData.estateId"
          placeholder="成交项目"
          class="mr10"
          clearable
          filterable
          @change="search"
        >
          <el-option
            v-for="item of estateList"
            :value="item.estateId"
            :label="item.estateName"
            :key="item.estateId"
          ></el-option>
        </el-select>
        <el-cascader
          v-model="queryData.mechanismId"
          :props="props"
          :options="mechanismCascadeList"
          clearable
          filterable
          placeholder="合作渠道"
          separator="-"
          class="mr10"
          @change="search"
        ></el-cascader>
        <el-select
          v-model="queryData.statusList"
          placeholder="结单状态"
          class="mr10"
          clearable
          collapse-tags
          multiple
          @change="search"
        >
          <el-option
            v-for="item of statusArr.filter(s => !s.hide)"
            :value="item.id"
            :label="item.name"
            :key="item.id"
          ></el-option>
        </el-select>
        <el-date-picker
          v-model="queryData.date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="search"
        >
        </el-date-picker>
        <el-checkbox
          class="ml10"
          label="已撤单"
          v-model="queryData.showCanceled"
          @change="search"
        ></el-checkbox>
      </div>
      <el-button-group class="actions-btn">
        <f-table-fields
          name="transactionManageTab1"
          :options="defaultFields"
          @change="onFieldsChange"
        >
          <el-button icon="iconfont icon-list"></el-button>
        </f-table-fields>
        <el-button icon="iconfont icon-refresh" @click="getList"></el-button>
      </el-button-group>
    </div>
    <f-table
      ref="table"
      class="mt20"
      border
      :data="list"
      :row-class-name="tableRowClassName"
      :columns="tableColumns"
    ></f-table>
    <div class="total-collect" v-if="list.length">
      合计应结金额：<span>¥{{ sumData.amount | toThousands }}</span> &#12288;
      合计已结金额：<span>¥{{ sumData.amountEnd | toThousands }}</span>
    </div>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
    <!--订单详情-->
    <order-detail
      ref="orderDetail"
      :visible.sync="detailDialog"
      :detailId="detailId"
      @cancelOrder="cancelOrder"
      @editOrder="showEditDialog"
      @handleReceipt="handleReceipt"
      @accountOrder="accountOrder"
    ></order-detail>
    <!--创建收款-->
    <receipt-dialog
      :visible.sync="receiptDialog"
      :editId="editId"
      :detail="detail"
      @success="handleSuccess"
    ></receipt-dialog>
    <!--编辑订单-->
    <edit-order-dialog
      :visible.sync="editDialog"
      :detailId="detailId"
      @success="handleSuccess"
    ></edit-order-dialog>
  </div>
</template>

<script>
import orderDetail from "../_components/orderDetail.vue";
import receiptDialog from "../_components/receiptDialog.vue";
import editOrderDialog from "../_components/editOrderDialog.vue";
import { dealOrderList, dealOrderStatus, dealCount } from "@/api/order";
export default {
  components: { orderDetail, receiptDialog, editOrderDialog },
  props: {
    // 成交项目
    estateList: {
      type: Array,
      default: () => []
    },
    // 合作渠道
    mechanismCascadeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      queryData: {
        pageNum: 1,
        pageSize: 10,
        keywords: "",
        estateId: null,
        mechanismId: null,
        statusList: [],
        date: null,
        // 是否显示已撤单的
        showCanceled: false
      },
      props: {
        label: "name",
        value: "id",
        children: "childrenList",
        emitPath: false
      },
      statusArr: [
        { id: 1, name: "待结单" },
        { id: 2, name: "结单中" },
        { id: 3, name: "已结单" },
        { id: 4, name: "已撤单", hide: true }
      ], //结单状态option
      total: 0,
      list: [],
      detailId: null,
      detail: {},
      detailDialog: false, //详情弹框
      receiptDialog: false, //收款弹框
      editDialog: false, //编辑弹框
      editId: null, //编辑收款id,
      sumData: {
        // 应结金额
        amount: 0,
        amountWait: 0,
        // 已结金额
        amountEnd: 0
      },
      // 需要展示的字段
      checkedFields: this.$storage.get("transactionManageTab1TableFields", []),
      // 默认字段选项
      defaultFields: [
        {
          label: "成交单号",
          prop: "dealNumber",
          checked: true,
          disabled: true
        },
        {
          label: "成交项目",
          prop: "estateName",
          checked: true,
          disabled: true
        },
        {
          label: "客户姓名",
          prop: "customerList",
          checked: true
        },
        {
          label: "成交房源",
          prop: "houseInfo",
          checked: true
        },
        {
          label: "成交金额",
          prop: "amount",
          checked: true
        },
        {
          label: "成交日期",
          prop: "dealDate",
          checked: true
        },
        {
          label: "合作渠道",
          prop: "mechanismId",
          checked: true
        },
        {
          label: "申报人员",
          prop: "inUserName",
          checked: true
        },
        {
          label: "应结金额",
          prop: "amountConclusion",
          checked: true
        },
        {
          label: "已结金额",
          prop: "amountEnd",
          checked: true
        },
        {
          label: "结单状态",
          prop: "status",
          checked: true
        },
        {
          label: "操作",
          prop: "operation",
          checked: true,
          disabled: true
        }
      ]
    };
  },
  computed: {
    tableColumns() {
      const defaultColumns = [
        {
          label: "成交单号",
          prop: "dealNumber",
          render: ({ row }) => {
            return (
              <el-link
                type="primary"
                onClick={() => {
                  this.detailId = row.id;
                  this.detailDialog = true;
                }}
              >
                {row.dealNumber}
              </el-link>
            );
          }
        },
        {
          label: "成交项目",
          prop: "estateName"
        },
        {
          label: "客户姓名",
          prop: "customerList",
          render: ({ row }) => (
            <span>{row.customerList.map(c => c.customerName).join("、")}</span>
          )
        },
        {
          label: "成交房源",
          prop: "houseInfo",
          placeholder: "-"
        },
        {
          label: "成交金额",
          prop: "amount",
          isComma: true,
          isPrice: true
        },
        {
          label: "成交日期",
          prop: "dealDate",
          isDate: true,
          dateType: 0
        },
        {
          label: "合作渠道",
          prop: "mechanismId",
          render: ({ row }) => (
            <div>
              <div>{row.mechanismId == -1 ? "自然到访" : ""}</div>
              <div>
                {row.mechanismName && row.mechanismId != -1
                  ? this.$format.link([
                      row.mechanismName,
                      row.mechanismStoreName
                    ])
                  : !row.mechanismName
                  ? "-"
                  : ""}
              </div>
            </div>
          )
        },
        {
          label: "申报人员",
          prop: "inUserName",
          placeholder: "-"
        },
        {
          label: "应结金额",
          prop: "amountConclusion",
          isPrice: true,
          isComma: true
        },
        {
          label: "已结金额",
          prop: "amountEnd",
          render: ({ row }) => (
            <div>
              {row.status != 4 && row.amountEnd ? (
                <span class="red">¥{this.$format.comma(row.amountEnd)}</span>
              ) : (
                "-"
              )}
            </div>
          )
        },
        {
          label: "结单状态",
          prop: "status",
          render: ({ row }) => (
            <span class={"status-" + row.status}>
              {this.statusArr[row.status - 1].name}
            </span>
          )
        },
        {
          label: "操作",
          prop: "operation",
          render: ({ row }) => (
            <div>
              {row.status == 1 || row.status == 2 ? (
                <div>
                  <el-link
                    type="primary"
                    onClick={() => this.handleReceipt(row)}
                  >
                    收款
                  </el-link>
                  <span style="padding: 0 10px;color: #aaa;">|</span>
                  <el-dropdown
                    placement="bottom-start"
                    onCommand={event => this.moreAction(event, row)}
                  >
                    <el-link type="primary">更多</el-link>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="accountOrder">
                        结单
                      </el-dropdown-item>
                      <el-dropdown-item command="showEditDialog">
                        编辑
                      </el-dropdown-item>
                      <el-dropdown-item command="cancelOrder">
                        撤单
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              ) : row.status == 3 ? (
                <div>
                  <el-link
                    type="primary"
                    onClick={() => this.showEditDialog(row)}
                  >
                    编辑
                  </el-link>
                  <span style="padding: 0 10px;color: #aaa;">|</span>
                  <el-link type="primary" onClick={() => this.cancelOrder(row)}>
                    撤单
                  </el-link>
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          )
        }
      ];
      const result = [];
      for (const field of this.checkedFields) {
        const columnIndex = defaultColumns.findIndex(c => c.prop === field);
        if (columnIndex > -1) {
          result.push(defaultColumns[columnIndex]);
          // 储存后就移除 减少下次循环次数
          defaultColumns.splice(columnIndex, 1);
        }
      }
      return result;
    }
  },
  created() {
    this.getList();
    this.getCount();
  },
  methods: {
    onFieldsChange({ checkedFields }) {
      this.checkedFields = checkedFields;
    },
    // 获取列表统计
    async getCount() {
      const res = await dealCount(this.queryData);
      if (res) {
        this.sumData = res;
      }
    },
    // 筛选
    search() {
      this.queryData.pageNum = 1;
      let date = this.queryData.date;
      if (date) {
        this.queryData.dateStart = date[0];
        this.queryData.dateEnd = date[1];
      } else {
        delete this.queryData.dateStart;
        delete this.queryData.dateEnd;
      }
      this.getList();
      this.getCount();
    },
    // 获取列表
    async getList() {
      const res = await dealOrderList(this.queryData);
      if (res) {
        this.total = res.total;
        this.list = res.list;
      }
    },
    // 给已撤单行添加className
    tableRowClassName({ row }) {
      if (row.status == 4) {
        return "tr-disabled";
      }
    },
    // 更多操作，结单、编辑、撤单
    moreAction(fnName, row) {
      this[fnName](row);
    },
    // 编辑订单
    showEditDialog(row) {
      this.editDialog = true;
      this.detailId = row.id;
    },
    // 创建收款
    handleReceipt(row, editId) {
      this.receiptDialog = true;
      this.detail = row;
      this.editId = editId || null;
    },
    // 结单
    accountOrder(row) {
      this.$confirm(
        "请确认是否已完成全部结款，结单后将无法创建收款，确定要结单？",
        "结单提示",
        {
          confirmButtonText: "结单",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(async () => {
        const res = await dealOrderStatus({ id: row.id, status: 3 });
        if (res) {
          this.$message.success("结单成功!");
          this.handleSuccess();
        }
      });
    },
    // 撤单
    cancelOrder(row) {
      this.$confirm("撤单后无法恢复，确认要撤单吗？", "撤单提示", {
        confirmButtonText: "撤单",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "cancel-sure-btn"
      }).then(async () => {
        const res = await dealOrderStatus({ id: row.id, status: 4 });
        if (res) {
          this.$message.success("撤单成功!");
          this.handleSuccess();
        }
      });
    },
    // 操作订单成功后刷新列表和订单详情
    handleSuccess() {
      this.getList();
      this.getCount();
      if (this.detailDialog) this.$refs.orderDetail.handleOpen();
    }
  }
};
</script>

<style scoped lang="scss">
.filter {
  display: flex;
  .m {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 30px;
    row-gap: 10px;
  }
  .el-input {
    width: 300px;
  }
  .el-select,
  .el-cascader {
    width: 154px;
  }
  .el-date-editor {
    width: 240px;
  }
  .actions-btn {
    display: flex;
    height: 40px;
    ::v-deep {
      .el-popover__reference {
        .el-button {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
::v-deep .tr-disabled {
  opacity: 0.63;
}
::v-deep {
  .status-1 {
    color: $red;
  }
  .status-2 {
    color: #a66400;
  }
  .status-3 {
    color: $green;
  }
  .status-4 {
    color: $color6;
  }
}
.total-collect {
  text-align: right;
  margin: 12px 0 20px;
  span {
    color: $red;
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
<style>
.cancel-sure-btn {
  background: red !important;
  border-color: red !important;
}
</style>
